import type { ImageLoader, ImageLoaderProps } from 'next/image';

import { variantsWithSizes } from '@hubcms/domain-images';
import { error } from '@hubcms/utils-monitoring';

import { getSizedUrl } from './get-sized-url';
import { getVariant } from './get-variant';

export const imageLoader: ImageLoader = ({ src, width }: ImageLoaderProps) => {
  const variant = getVariant(src);
  if (!variant) {
    error(`no variant found for url ${src} - returning original src`);
    return src;
  }
  const sizes = variantsWithSizes[variant];
  const sizeCandidates = sizes.filter(c => c > width);
  const smallestCandidate = sizeCandidates.pop() || Math.max(...sizes);

  return getSizedUrl(src, variant, smallestCandidate);
};

export const createImageSrcSet = (src: string, constraints: [min: number | null, max: number | null] = [null, null]): string => {
  const variant = getVariant(src);
  if (!variant) {
    error(`no variant found for url ${src} - returning original src`);
    return src;
  }

  const clampedSizes = variantsWithSizes[variant].filter(size => isClamped(size, ...constraints));
  const sizes = clampedSizes.length > 0 ? clampedSizes : variantsWithSizes[variant];

  return sizes.map(size => `${getSizedUrl(src, variant, size)} ${size}w`).join(', ');
};

function isClamped(size: number, min: number | null, max: number | null) {
  return (min === null || size >= min) && (max === null || size <= max);
}

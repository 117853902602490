import cx from 'classnames';

import type { ImageSizes, TeaserImageFormat } from '@hubcms/domain-images';
import type { TeaserImage } from '@hubcms/domain-teaser';
import { createImageSizes, createImageSrcSet } from '@hubcms/utils-images';

import { getImageDetails } from '../../utils/getImageDetails';

import styles from './native-teaser-image.module.scss';

type Props = {
  image: TeaserImage;
  format: TeaserImageFormat;
  isPriority?: boolean;
  sizes?: ImageSizes;
  className?: string;
};

export function NativeTeaserSimpleImage({ image, format, sizes, isPriority = false, className }: Props) {
  const { url: defaultSrc, caption: defaultCaption, aspectRatio } = getImageDetails(image, format);

  return (
    <img
      src={defaultSrc}
      alt={defaultCaption}
      className={cx(className, styles.nativeTeaserImage, aspectRatio)}
      srcSet={createImageSrcSet(defaultSrc)}
      sizes={createImageSizes(sizes)}
      loading={isPriority ? 'eager' : 'lazy'}
    />
  );
}
